.layout {
  & .header {
    height: 70px;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    background: #482453;

    & .logo {
      height: 42px;
      margin: 14px;
      cursor: pointer;
      filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
    }
  }

  & .main {
    margin: 0 auto;
    max-width: 900px;
    margin-bottom: 40px;
    min-height: calc(100vh - 240px);
  }

  & .footer {
    min-width: 360px;
    height: 130px;
    color: white;
    font-weight: 500;
    text-align: center;
    background-color: #eb5757;

    & .logo {
      padding: 10px;
      display: block;

      & img {
        height: 28px;
      }
    }

    & .brand {
      margin-bottom: 20px;
    }

    & .nav {
      font-family: "Rubik", sans-serif;
    }
  }
}

.homepage {
  background: linear-gradient(10deg, #27142d 0%, #27142d 40%, #4f3358 40%, #4f3358 70%, #6b3d7a 70%);

  & .header {
    background: unset;
  }
}
