.area {
  margin-top: 48px;
  color: #f4eef5;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 2fr 1fr;
  gap: 0px 0px;
  padding: 0px 20px;

  & .left {
    width: 440px;

    & .header {
      font-family: "Rubik", sans-serif;
      font-size: 46px;
    }

    & .detail {
      font-family: "Poppins", sans-serif;
      font-size: 24px;
      margin-bottom: 48px;
    }
  }

  & .phone {
    height: 480px;
    align-self: end;
  }

  & .circles {
    height: 240px;
  }
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;

  & .text {
    padding: 10px 20px;
    font-size: 24px;
    font-family: "Rubik", sans-serif;
  }
}

@media (max-width: 768px) {
  .area {
    width: 360px;
    margin: 0 auto;
    display: block;
    text-align: center;

    & .left {
      width: 100%;

      & .header {
        font-size: 34px;
      }

      & .detail {
        font-size: 20px;
      }
    }

    & .phone {
      margin-top: 20px;
    }
  }

  .action {
    text-align: center;
    display: block;

    & .text {
      margin-bottom: 16px;
    }
  }
}
